body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

.transform-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.transform-centerX {
  left: 50%;
  transform: translateX(-50%);
}
.transform-centerY {
  left: 50%;
  transform: translateY(-50%);
}
[class*='col-'] {
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.col-lg-6 {
  width: 50%;
}
.col-lg-4 {
  width: 33.33%;
}
.clearfix {
  &:before, &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.blue-bg {
  background-color: #003f63;
}
.module-section {
  padding-top: 80px;
}
.section-main-heading {
  font-weight: 400;
  color: #0189D0;
  font-size: 32px;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 3px;
    width: 120px;
    background-color: #0189D0;
  }
}
.section-title {
  font-weight: 600;
}
.tiles-wrapper {
  @extend .clearfix;
}
.section-size {
  width: 80%;
  margin: auto;
  padding: 20px 30px;
}

.module-section {
  .section-body {
    width: 80%;
    margin: auto;
    padding: 20px 30px;
  }
}

.main-container {
  .main-header {
    height: 80px;
    padding: 0 10%;
    margin: auto;
    clear: both;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    .logo-container {
      float: left;
      height: 100%;
      text-align: center;
      img {
        height: 100%;
      }
    }

    .navbar-container {
      float: right;
      width: calc(100% - 300px);
      height: 100%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .menu-item {
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        height: 100%;
        cursor: pointer;
        transition: ease all .3s;
        color: $menu-content-color;
        text-decoration: none;
        text-shadow: 0 0 1px $bg-color-light;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;

        &:hover {
          background-color: $menu-hover-bg-color;
          color: $bg-color-light;
          text-shadow: none;
        }
      }
    }
  }

  .body-main-container {
    #home {
      background: url("../images/bg.png");
      background-size: cover;
      min-height: 100vh;
      height: 610px;
      position: relative;
      .main-banner-content {
        min-width: 500px;
        padding: 20px;
        background-color: #ffffff;
        text-align: center;
        color: #003F60;
        position: absolute;
        @extend .transform-center;
        .heading {
          display: block;
          font-size: 40px;
          line-height: 58px;
          padding-bottom: 15px;
          margin-bottom: 20px;
          &:after {
            content: "";
            display: block;
            height: 3px;
            position: relative;
            background-color: #003F60;
            top: 10px;
            width: 200px;
            @extend .transform-centerX;
          }

        }
        .content {
          display: block;
          font-size: 24px;
        }
      }
    }

    #about {
      padding-top: 0;
      .section-body {
        width: 100%;
        padding: 0;
        .blue-bg {
          padding-bottom: 300px;
        }
        .section-right-layer {
          background: #fff;
          @extend .section-size;
        }
        .our-beliefs {
          @extend .section-size;
          padding-top: 80px;
          color: #ffffff;
          .section-title {
            color: #85D5FF;
            text-align: center;
          }
          .section-title-sub-content {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin-top: 5px;
          }
          .section-content {
            font-weight: 300;
            line-height: 22px;
          }
        }
        .tiles-wrapper {
          margin-top: -300px;
          @extend .section-size;
          .tile {
            position: relative;
            .tile-heading {
              color: #ffffff;
              font-size: 20px;
              font-weight: 400;
            }
            .tile-body {
              padding: 0;
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
              img {
                width: 100%;
              }
              .section-content {
                margin: 0;
                padding: 20px;
                line-height: 24px;
                font-weight: 300;
              }
            }
          }
        }
      }
    }

    #services {
      .section-body {
        @extend .clearfix;
        .section-content {
          line-height: 24px;
        }
        img {
          width: 100%;
          height: 225px;
        }
      }
    }

    #customer-review {
      .section-body {
        position: relative;
        &:after {
          content: '';
          display: block;
          background: url("../images/icons/double-quotes-close.png") no-repeat;
          background-size: 100%;
          position: absolute;
          bottom: 0;
          right: -50px;
          width: 112px;
          height: 85px;
        }
      }
      .section-main-heading {
        position: relative;
        &:before {
          content: '';
          display: block;
          background: url("../images/icons/double-quotes-open.png") no-repeat;
          background-size: 100%;
          position: absolute;
          top: 0;
          left: -50px;
          width: 112px;
          height: 85px;
        }
      }
      .customer-review-wrapper {
        padding-top: 30px;
        display: flex;
        align-items: center;
        .customer-review-content {
          font-size: 13px;
          font-weight: 300;
          flex: 1;
          .customer-review-content-box {
            background: #ffffff;
            opacity: 0.4;
            box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.15);
            padding: 15px 20px;
            margin: 25px 0;
            cursor: pointer;
            transition: ease all .3s;
            &:hover {
              transform: scale(1.05);
            }
            &.active {
              box-shadow: 0 0 8px 3px rgba(0, 63, 96, 0.25);
              opacity: 1;
              pointer-events: none;
              transform: scale(1.1);
              .customer-info {
                display: block;
              }
            }
            .customer-info {
              margin-top: 15px;
              display: none;
              .customer-name {
                font-weight: 600;
              }
            }
          }
        }
        .customer-review-pic {
          padding-left: 50px;
          text-align: center;
          flex: 1;
          img {
            max-width: 80%;
            border-radius: 50%;
          }
        }
      }
    }

    #clients {
      .section-body {
        .client-logo {
          width: 16.66%;
          display: inline-block;
          padding: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  footer {
    background-color: #003F60;
    padding: 30px 20% 0;
    color: #ffffff;
    .footer-content {
      display: flex;
      align-items: center;
    }
    .contact-info {
      //width: 50%;
      .sub-heading {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      a {
        text-decoration: none;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 5px;
        display: block;
      }
      .address {
        margin-top: 20px;
        font-weight: 600;
      }
    }
    .social-media {
      flex: 1;
      text-align: right;
      a {
        display: inline-block;
        margin: 0 10px;
      }
      .icon {
        @extend .icon-medium;
        &:before {
          transition: ease all .3s;
        }
        &:hover {
          &:before {
            transform: scale(1.5);
          }
        }
      }
    }
    .copy-right {
      text-align: center;
      padding: 20px;
      font-weight: 600;
      margin-top: 10px;
    }
  }

}

@media (max-width: 991px) {
  body {
    padding-bottom: 60px;
    min-width: 320px;
  }
  .col-sm-12 {
    width: 100%;
  }

  .module-section {
    padding-top: 30px;
    .section-body {
      width: 90%;
      padding: 20px
    }
  }

  .main-container {
    .main-header {
      padding: 0;
      text-align: center;
      .logo-container {
        float: none;
      }
      .navbar-container {
        position: fixed;
        width: 100vw;
        background-color: #fff;
        height: 60px;
        float: none;
        justify-content: center;
        bottom: 0;
        left: 0;
        text-align: center;
        border-top: 1px solid #003f63;
        .menu-item {
          padding: 0 10px;
          font-size: 12px;
          font-weight: 600;
          flex: auto;
        }
      }
    }
    .body-main-container {
      #home {
        background-position-x: -2px;
        .main-banner-content {
          min-width: unset;
          max-width: 100vw;
          padding: 0 20px;
        }
      }

      #about {
        .section-body {
          .section-right-layer {
            padding: 20px;
            width: 90%;
          }
          .blue-bg {
            padding-bottom: 0;
          }
          .tiles-wrapper {
            width: 100%;
            padding: 20px;
            margin: 0;
            .tile {
              padding: 0;
            }
          }
          .our-beliefs .section-content {
            text-align: center;
          }
        }
      }

      #customer-review {
        .section-main-heading {
          &:before {
            left: -35px
          }
        }
        .section-body {
          &:after {
            right: -10px
          }
        }
        .customer-review-wrapper {
          display: block;
          .customer-review-pic {
            padding-left: unset;
          }
        }

      }

      #clients {
        .section-body {
          .client-logo {
            width: 33.33%;
          }
        }
      }
    }
    footer {
      padding: 20px 20px 0;
      .footer-content {
        .contact-info {
          width: 70%;
        }
      }
      .social-media {
        text-align: center;
        width: 30%;
        a {
          margin: 10px;
          display: block;
        }
      }
    }
  }
}
